import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Layout from "../components/new/Layout";
import Spacer from "../components/new/Spacer";
import Heading from "../components/new/typography/Heading";

const Container = styled.div`
  min-height: calc(90vh - 80px);
`;

const CookieSettingsPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`Cookie settings`}
        titleTemplate={`%s | IPG - DXTRA`}
      />
      <Layout navigationTransparent={false}>
        <Container>
          <Grid>
            <Row>
              <Col xs={12} mdOffset={1} md={10} lgOffset={2} lg={8}>
                <Spacer padding={{ xs: "80px 0", m: "180px 0" }}>
                  <Heading>Cookie settings</Heading>
                </Spacer>
              </Col>
            </Row>
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

CookieSettingsPage.propTypes = {};

export default CookieSettingsPage;
